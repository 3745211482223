<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb slot="header" separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ name: 'campaignIndex' }">{{ $t('seller.campaign.title') }}</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.goodsManage') }}</el-breadcrumb-item>
			</el-breadcrumb>
			<el-form inline size="medium" class="page-top">
				<el-form-item :label="$t('seller.screen.campaignSearchId') + '：'">
					<el-input v-model="keyword" :placeholder="$t('seller.placeholder.campaignSearchId')" style="width: 320px;">
					</el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="handleSearchTop">{{ $t('seller.actions.search') }}</el-button>
					<el-button type="seller__clear" icon="el-icon-brush" @click="handleClearTop">{{ $t('seller.actions.clearQuery') }}</el-button>
				</el-form-item>
			</el-form>
			<div class="page-filters" v-if="filtersFlag">
				<el-form size="medium" inline>
					<el-form-item :label="$t('seller.screen.campaignName') + ':'">
						<el-input v-model="pageQuery.activity_name" :placeholder="$t('seller.placeholder.default')" clearable>
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.goodsSignUpStatus') + ':'">
						<el-select v-model="pageQuery.enroll_status" clearable>
							<el-option v-for="item in $t('seller.options.goodsSignUpStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.goodsPerfectStatus') + ':'">
						<el-select v-model="pageQuery.per_status" clearable>
							<el-option v-for="item in $t('seller.options.perfectStatus')" :key="item.label" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.campaignTag') + ':'">
						<el-select v-model="pageQuery.activity_label_id" clearable>
							<el-option v-for="item in tagsOptions" :key="item.activity_label_id"
								:label="item.label_name" :value="item.activity_label_id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.goodsId') + ':'">
						<el-input v-model="pageQuery.goods_ids" :placeholder="$t('seller.placeholder.default')" clearable>
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.goodsName') + ':'">
						<el-input v-model="pageQuery.goods_name" :placeholder="$t('seller.placeholder.default')" clearable>
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('seller.campaign.activityTime') + ':'">
						<el-date-picker v-model="campaignTime" type="datetimerange" range-separator="-"
							:start-placeholder="$t('seller.placeholder.originDate')"
							:end-placeholder="$t('seller.placeholder.endDate')">
						</el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('seller.campaign.signUpTime') + ':'">
						<el-date-picker v-model="applicationTime" type="datetimerange" range-separator="-"
							:start-placeholder="$t('seller.placeholder.originDate')"
							:end-placeholder="$t('seller.placeholder.endDate')">
						</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSearchFilter">{{ $t('seller.actions.search') }}</el-button>
						<el-button type="seller__clear" icon="el-icon-brush" @click="handleSearchReset">{{ $t('seller.actions.clearQuery') }}</el-button>
					</el-form-item>
				</el-form>
				<div class="filters-sort">
					<el-radio-group v-model="pageQuery.order_field" size="medium" @change="handleChangeSort">
						<el-radio :label="1" border><i class="el-icon-sort-down"></i> {{ $t('seller.screen.signUpTimeSort') }}</el-radio>
						<el-radio :label="2" border><i class="el-icon-sort-down"></i> {{ $t('seller.screen.campaignTimeSort') }}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<el-table v-loading="listLoading" :data="pageList" border class="seller-table">
				<el-table-column :label="$t('seller.screen.goodsName')">
					<template slot-scope="scope">
						<div class="goods-item">
							<div class="pic">
								<img :src="scope.row.default_image" @error="goodsLoadError" alt="">
								<p>{{ scope.row.is_violation === 1 ? 2 : scope.row.if_show | typeFilter($t('seller.filters.goodsType'))}}</p>
							</div>
							<div class="item-main">
								<div class="name"><a :href="$itemBasePath+'/item/'+scope.row.goods_id" target="_blank">{{ scope.row.goods_name }}</a></div>
								<div class="text">
									<span class="text-label">{{ $t('seller.screen.goodsId') }}</span>
									<span>{{ scope.row.goods_id }}</span>
								</div>
								<div class="text">
									<span class="text-label">{{ $t('seller.campaign.marketId') }}</span>
									<span>{{ scope.row.activity_goods_id }}</span>
								</div>
								<div class="text">
									<span class="text-label">{{ $t('seller.tableHead.stock') }}</span>
									<span>{{ scope.row.activity_stock > 0 ? scope.row.activity_stock : $t('seller.campaign.allStock') }}</span>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.campaignInfo')" min-width="100px">
					<template slot-scope="scope">
						<div class="text-item">
							<span class="item-label">{{ $t('seller.campaign.tags') }}</span>
							<span class="item-block">{{ scope.row.label_name }}</span>
						</div>
						<div class="text-item">
							<span class="item-label">{{ $t('seller.campaign.name') }}</span>
							<router-link :to="{ name: 'campaignDetail', params: {id: scope.row.activity_id }}" class="item-block">{{ scope.row.name }}</router-link>
						</div>
						<div class="text-item">
							<span class="item-label">{{ $t('seller.campaign.signUp') }}</span>
							<span class="item-block">{{ scope.row.s_enroll_start_time | parseTime() }}</span>
						</div>
						<div class="text-item">
							<span class="item-label">{{ $t('seller.campaign.activity') }}</span>
							<span class="item-block">{{ scope.row.s_enroll_start_time | parseTime('{y}.{m}.{d} {h}:{i}') }}-{{ scope.row.sales_end_time  | parseTime('{y}.{m}.{d} {h}:{i}') }}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.tableHead.campaignPrice')" align="center">
					<template slot-scope="scope">
						<div class="price-item">
							<span class="item-label">{{ $t('seller.formItem.goodsPrice') }}</span>
							<span class="item-block">{{ scope.row.price | priceFilter() }}</span>
						</div>
						<div class="price-item" v-if="parseFloat(scope.row.activity_price) > 0">
							<span class="item-label">{{ $t('seller.campaign.activityPrice') }}</span>
							<span class="item-block">{{ scope.row.activity_price | priceFilter() }}</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column :label="$t('seller.screen.goodsStatus')" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.enroll_status > 0 ? $t('seller.campaign.activityInProgress'):$t('seller.campaign.draft') }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="handleEditShow(scope)">{{ scope.row.enroll_status > 0 ? $t('seller.actions.editGoods'):$t('seller.actions.perfectGoods')}}</el-button>
					</template>
				</el-table-column>
			</el-table>
			<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr" :limit.sync="pageSize"
				@pagination="getList"></pagination>
			<el-dialog :title="$t('seller.actions.editGoods')+'-'+postForm.goods_name" :visible.sync="dialogVisibleDetails" width="900px" custom-class="seller-dialog">
				<el-form ref="postForm" :model="postForm" :rules="postRules" label-width="120px" size="small" class="dialog-form">
					<el-form-item :label="$t('seller.screen.goodsTitle')"><a :href="$itemBasePath+'/item/'+postForm.goods_id" class="link-text" target="_blank">{{ postForm.goods_name }}</a></el-form-item>
					<el-form-item :label="$t('seller.screen.goodsId')">{{ postForm.goods_id }}</el-form-item>
					<el-form-item :label="$t('seller.screen.signUpType')">
						<el-radio v-model="postForm.enroll_type" :label="0">{{ $t('seller.campaign.signUpTips') }}</el-radio>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.buyItNow')">{{ postForm.price }}{{ $t('seller.unit.yuan') }}</el-form-item>
					<el-form-item :label="$t('seller.screen.campaignGoodsPrice')" prop="activity_price">
						<el-input v-model="postForm.activity_price" oninput="value=value.match(/^\d+(?:\.\d{0,2})?/)" style="width: 120px">
							<template slot="append">{{ $t('seller.unit.yuan') }}</template>
						</el-input>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.stockType')">
						<el-radio v-model="postForm.stock_type" :label="0">{{ $t('seller.campaign.stockTips') }}</el-radio>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.signUpStock')" prop="activity_stock">
						<el-input v-model="postForm.activity_stock" oninput="value=value.replace(/[^\d]/g,'')" style="width: 120px">
							<template slot="append">{{ $t('seller.unit.piece') }}</template>
						</el-input>
						<span class="tips">{{ $t('seller.campaign.stockCurr') + '：' }}{{postForm.stock}}{{ $t('seller.unit.piece') }}</span>
					</el-form-item>
					<el-form-item :label="$t('seller.screen.limitBuy')" prop="limit_quantity">
						<el-input v-model="postForm.limit_quantity" oninput="if(value<1)value=1;if(value>9999)value=9999;value=value.replace(/[^\d]/g,'')" style="width: 120px"></el-input>
						<span>{{ $t('seller.unit.piece') }}</span>
						<span class="tips">{{ $t('seller.campaign.limitTips1') }}</span> 
						<div class="tips">{{ $t('seller.campaign.limitTips2') }}</div>
					</el-form-item>
					<el-form-item>
						<el-button size="small" @click="dialogVisibleDetails = false">{{ $t('seller.actions.cancel') }}</el-button>
						<el-button type="primary" size="small" v-loading="dialogLoaidng" @click="handleSubmit">{{ $t('seller.actions.submit') }}</el-button>
					</el-form-item>
				</el-form>
			</el-dialog>
		</el-card>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchCampaignSignItems, fetchCampaignTags, uploadCampaignGoods } from '@/api/seller/market'
const queryDefault = {
	activity_name: '',
	enroll_status: '',
	goods_ids: '',
	goods_name: '',
	per_status: '',
	activity_label_id: '',
	order_field: 1,
	order_type: 'desc'
}
export default {
	components: { Pagination },
	data() {
		return {
			keyword: '',
			filtersFlag: true,
			campaignTime: '',
			applicationTime: '',
			tagsOptions: [],
			pageQuery:Object.assign({}, queryDefault),
			listLoading: false,
			pageList: [],
			pageCurr: 1,
			pageSize: 10,
			pageTotal: 0,
			dialogVisibleDetails: false,
			postForm: {
				activity_price: '',
				activity_stock: '',
				limit_quantity: 1
			},
			postRules: {
				activity_price: [
					{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' }
				],
				activity_stock: [
					{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' }
				],
				limit_quantity: [
					{ required: true, message: this.$t('seller.validator.required'), trigger: 'change' }
				]
			},
			dialogLoaidng: false
		}
	},
	created() {
		this.getData()
	},
	methods: {
		goodsLoadError(e) {
			e.target.src = this.$imageErrorGoods;
		},
		async getData() {
			try {
				const tags = await fetchCampaignTags()
				this.tagsOptions = tags.data.list
			} catch (error) {
				console.log(error)
			}
			this.getList()
		},
		getList() {
			this.listLoading = true
			const params = {
				...this.pageQuery,
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			if(this.keyword) {
				params.sale_goods_id = this.keyword
			}
			if (this.campaignTime) {
				params.sales_start_time = parseInt(this.campaignTime[0] / 1000)
				params.sales_end_time = parseInt(this.campaignTime[1] / 1000)
			}
			if (this.applicationTime) {
				params.s_enroll_start_time = parseInt(this.applicationTime[0] / 1000)
				params.g_enroll_end_time = parseInt(this.applicationTime[1] / 1000)
			}
			fetchCampaignSignItems(params).then(response => {
				const { data } = response
				this.pageList = data.list
				this.pageTotal = parseInt(data.count)
				this.listLoading = false
			})
		},
		handleSearchTop() {
			this.pageQuery = Object.assign({}, queryDefault)
			this.campaignTime = ''
			this.applicationTime = ''
			this.pageCurr = 1
			this.filtersFlag = false
			this.getList()
		},
		handleClearTop() {
			this.pageQuery = Object.assign({}, queryDefault)
			this.keyword = ''
			this.campaignTime = ''
			this.applicationTime = ''
			this.pageCurr = 1
			this.filtersFlag = true
			this.getList()
		},
		handleSearchFilter() {
			this.pageCurr = 1
			this.getList()
		},
		handleSearchReset() {
			this.pageQuery = Object.assign({}, queryDefault)
			this.campaignTime = ''
			this.applicationTime = ''
			this.pageCurr = 1
			this.getList()
		},
		handleChangeSort(val) {
			this.pageCurr = 1
			this.getList()
		},
		handleEditShow({ row }) {
			this.postForm = Object.assign({},row)
			if(!this.postForm.activity_stock > 0) {
				this.postForm.activity_stock = ''
			}
			if(!this.postForm.activity_price > 0) {
				this.postForm.activity_price = ''
			}
			this.dialogVisibleDetails = true
			this.$nextTick(() => {
				this.$refs.postForm.clearValidate()
			})
		},
		handleSubmit() {
			if(this.postForm.activity_stock > this.postForm.stock) {
				this.$message.error(this.$t('seller.errorMsg.activityStock'))
				return false
			}
			this.$refs.postForm.validate(valid => {
				if(valid) {
					this.dialogLoaidng = true
					const params = {
						...this.postForm
					}
					uploadCampaignGoods(params).then(()=> {
						this.dialogLoaidng = false
						this.dialogVisibleDetails = false
						this.getList()
					}).catch(() => {
						this.dialogLoaidng = false
					})
				} else {
					return false
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.page-filters {
		padding-top: 20px;
		border-top: 1px solid #e6e8ed;
		.filters-sort {
			margin-bottom: 18px;
			::v-deep .el-radio-group {
				.el-radio {
					margin-right: 12px;
					border-radius: 18px;
					.el-radio__inner {
						width: 0;
						height: 0;
						border: none;
					}
				}
			}
		}
	}
	.goods-item {
		display: flex;
		font-size: 12px;
		line-height: 1.5;
		.pic {
			flex-shrink: 0;
			width: 60px;
			height: 78px;
			border-radius: 8px;
			overflow: hidden;
			text-align: center;
			img {
				width: 60px;
				height: 60px;
				object-fit: cover;
			}
			p {
				color: #FFFFFF;
				background-color: $--seller-primary;
			}
		}
		.item-main {
			margin-left: 10px;
			.name {
				color: $--seller-primary;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				margin-bottom: 4px;
				a {
					color: inherit;
				}
			}
			.text {
				font-size: 12px;
				span {
					display: inline-block;
					vertical-align: top;
					&.text-label {
						width: 52px;
						color: #999999;
					}
				}
			}
		}
	}
	.text-item {
		font-size: 12px;
		line-height: 1.5;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		.item-label {
			color: #999;
			margin-right: 6px;
		}
		a {
			color: $--seller-primary;
		}
	}
	.price-item {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		line-height: 1.5;
		.item-label {
			color: #999;
			margin-right: 6px;
		}
		.item-block {
			color: #f40;
		}
	}

}

.dialog-form {
	::v-deep .el-input {
		.el-input-group__append {
			padding: 0 10px;
		}
	}
	span {
		margin-left: 10px;
	}
	.tips {
		font-size: 12px;
		color: #999999;
	}
	::v-deep .el-radio {
		.el-radio__input {
			&.is-checked + .el-radio__label {
				color: #606266;
			}
		}
	}
	.link-text {
		color: $--seller-primary;
	}
}
</style>